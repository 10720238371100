import React, { useState } from "react";
import snakeFeet from "../assets/images/game/snakeFeet.png"; // Background illustration
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg"; // Background texture
import sixthSenseIcon from "../assets/images/icons/sixthSenseIcon2-removebg.png"; // Sixth Sense icon
import fiveHeadsIcon from "../assets/images/icons/fiveHeadsIcon-removebg.png"; // Five Heads icon

function CommunitySection() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            style={{
                position: "relative",
                backgroundImage: `url(${rockTexture})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                overflow: "hidden",
                textAlign: "center",
                paddingBottom: "100px",
            }}
        >
            {/* Dark Tint Effect */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    zIndex: 1,
                }}
            ></div>

            <div
                style={{
                    position: "relative",
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "35px 0",
                    textAlign: "center",
                    transform: "translateY(125px)",
                    zIndex: 1,
                    clipPath: "polygon(0% 8%, 10% 2%, 20% 6%, 30% 3%, 40% 7%, 50% 4%,60% 7%, 70% 3%, 80% 6%, 90% 2%, 100% 8%,100% 100%, 0% 100%)"
                }}
            >
                {/* Main Content */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "80%",
                        margin: "0 auto",
                        position: "relative",
                        zIndex: 2,
                        paddingTop: "50px",
                    }}
                >
                    {/* Left Side: Image */}
                    <div
                        style={{
                            flex: "0 1 60%",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <img
                            src={snakeFeet}
                            alt="Community"
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </div>

                    {/* Right Side: Text Content */}
                    <div
                        style={{
                            flex: "0 1 40%",
                            textAlign: "left",
                            paddingLeft: "40px",
                        }}
                    >
                        <h2
                            style={{
                                fontSize: "3rem",
                                fontWeight: "bold",
                                fontFamily: "'Nunito', sans-serif",
                                textTransform: "uppercase",
                                color: "#FFFFFF",
                            }}
                        >
                            COME JOIN US!
                        </h2>
                        <p
                            style={{
                                fontSize: "1.2rem",
                                fontFamily: "'Nunito', sans-serif",
                                color: "#DDD",
                                marginBottom: "20px",
                            }}
                        >
                            We welcome you to join our community where you can play the game early, participate in play
                            tests, give feedback, or just hang out!
                        </p>

                        {/* Community Links */}
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                marginBottom: "30px",
                            }}
                        >
                            <a href="https://discord.gg/Y5Szh2Dkdc" target="_blank" rel="noopener noreferrer"
                               style={{textDecoration: "none"}} // Ensures no underline
                            >
                                {/* Sixth Sense Discord Button */}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "#40cc8f",
                                        color: "#fff",
                                        padding: "20px",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        fontFamily: "'Nunito', sans-serif",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                                        width: "160px",
                                        height: "120px",
                                        clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)"
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.background = "#FAFAFA";
                                        e.currentTarget.style.color = "#40cc8f";
                                        e.currentTarget.querySelector("img").style.background = "#FAFAFA";
                                        e.currentTarget.style.transform = "scale(1.1)";
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.background = "#40cc8f";
                                        e.currentTarget.style.color = "#fff";
                                        e.currentTarget.querySelector("img").style.background = "transparent";
                                        e.currentTarget.style.transform = "scale(1)";
                                    }}
                                >
                                    <img
                                        src={sixthSenseIcon}
                                        alt="Sixth Sense Icon"
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            background: "transparent",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    SIXTH SENSE DISCORD
                                </div>
                            </a>
                            {/* Five Heads Discord Button */}
                            <a href="https://discord.gg/ehcAzCpVuf" target="_blank" rel="noopener noreferrer"
                               style={{textDecoration: "none"}}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "#40cc8f",
                                        color: "#fff",
                                        padding: "20px",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        fontFamily: "'Nunito', sans-serif",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                                        width: "160px",
                                        height: "120px",
                                        clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)"
                                    }}
                                    onMouseOver={(e) => {
                                        e.currentTarget.style.background = "#FAFAFA";
                                        e.currentTarget.style.color = "#40cc8f";
                                        e.currentTarget.querySelector("img").style.background = "#FAFAFA";
                                        e.currentTarget.style.transform = "scale(1.1)";
                                    }}
                                    onMouseOut={(e) => {
                                        e.currentTarget.style.background = "#40cc8f";
                                        e.currentTarget.style.color = "#fff";
                                        e.currentTarget.querySelector("img").style.background = "transparent";
                                        e.currentTarget.style.transform = "scale(1)";
                                    }}
                                >
                                    <img
                                        src={fiveHeadsIcon}
                                        alt="Five Heads Icon"
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            background: "transparent",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    FIVE HEADS DISCORD
                                </div>
                            </a>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "gray",
                                    color: "#fff",
                                    padding: "20px",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    fontFamily: "'Nunito', sans-serif",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    cursor: "pointer",
                                    transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                                    width: "160px",
                                    height: "120px",
                                    clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)"
                                }}
                                onMouseOver={() => setIsHovered(true)}
                                onMouseOut={() => setIsHovered(false)}
                            >
                                {isHovered ? "coming soon!" : "SnakeFeet Rewards"}
                            </div>
                        </div>

                        {/* Newsletter Section */}
                        <h3
                            style={{
                                fontSize: "1.8rem",
                                fontWeight: "bold",
                                fontFamily: "'Nunito', sans-serif",
                                color: "#FFFFFF",
                                marginBottom: "10px",
                            }}
                        >
                            NEWSLETTER
                        </h3>
                        <p
                            style={{
                                fontSize: "1.1rem",
                                fontFamily: "'Nunito', sans-serif",
                                color: "#DDD",
                                marginBottom: "20px",
                            }}
                        >
                            For all the latest news and announcements, sign up below.
                        </p>
                        <a
                            style={{
                                display: "inline-block",
                                background: "gray",
                                color: "#fff",
                                padding: "15px 40px",
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                fontFamily: "'Nunito', sans-serif",
                                textDecoration: "none",
                                textTransform: "uppercase",
                                borderRadius: "5px",
                                transition: "transform 0.2s ease-in-out",
                                clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)",
                                marginBottom: "20px"
                            }}
                            onMouseOver={(e) => {
                                e.target.style.background = "#FAFAFA";
                                e.target.style.color = "gray";
                                e.target.style.transform = "scale(1.1)";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.background = "gray";
                                e.target.style.color = "#fff";
                                e.target.style.transform = "scale(1)";
                            }}
                        >
                            coming soon
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommunitySection;