import { BrowserRouter as Router, Switch} from 'react-router-dom';
import HomeOne from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Overview from './pages/game/GameBody1';
import About from './pages/about/About';
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import GameSingleArea from "./pages/game/GameBody2";


function App() {
  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
        <ScrollToTopRoute exact={true} path='/'>
        <HomeOne/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/contact'>
        <Contact/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/sixthsense'>
        <Overview/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/fiveheads'>
        <GameSingleArea/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/about'>
        <About/>
        </ScrollToTopRoute>
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;
