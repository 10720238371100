import React from "react";
import fiveHeadsImage from "../assets/images/game/fiveHeads.png"; // Ensure the correct path to the image
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg"; // Rock texture background
import snakeFeet from "../assets/images/game/snakeFeet.png";

function FeaturedPanels() {
    return (
        <div
            style={{
                position: "relative",
                backgroundImage: `url(${rockTexture})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                padding: "0px 0",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            {/* Dark Tint Effect */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    zIndex: 1, // Ensures overlay is behind content
                }}
            ></div>

            {/* Content Section (Image + Text) */}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    padding: "50px",
                    borderRadius: "10px",
                    width: "80%",
                    position: "relative",
                    zIndex: 2,
                }}
            >
                {/* Left Side: Image */}
                <div
                    style={{
                        flex: "0 1 50%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    <img
                        src={fiveHeadsImage}
                        alt="Five Heads"
                        style={{
                            maxWidth: "90%",
                            height: "auto",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 15px rgba(255, 255, 255, 0.5)",
                        }}
                    />
                </div>

                {/* Right Side: Text Content */}
                <div
                    style={{
                        flex: "0 1 50%",
                        textAlign: "left",
                        padding: "0 40px",
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    <h2
                        style={{
                            fontSize: "3rem",
                            marginBottom: "20px",
                            color: "#75c9f5",
                            textTransform: "uppercase",
                            fontFamily: "'Nunito', sans-serif",
                        }}
                    >
                        FIVE HEADS: COMING SOON
                    </h2>
                    <p
                        style={{
                            fontSize: "1.2rem",
                            lineHeight: "1.5",
                            fontFamily: "'Nunito', sans-serif",
                            color: "#DDD",
                            marginBottom: "30px",
                            textAlign: "justify", // Ensures equal-length lines
                        }}
                    >
                        Five prisoners, five cards, one common goal. Use logic, deduction, and psychology to
                        piece together the truth and uncover your own card.
                    </p>

                    {/* Button Wrapper - Moves Button to Right */}
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <a
                            href="/fiveheads"
                            style={{
                                display: "inline-block",
                                background: "#75c9f5",
                                color: "#fff",
                                padding: "15px 40px",
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                fontFamily: "'Nunito', sans-serif",
                                textDecoration: "none",
                                textTransform: "uppercase",
                                borderRadius: "5px",
                                transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                                clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)",
                            }}
                            onMouseOver={(e) => {
                                e.target.style.background = "#FAFAFA";
                                e.target.style.color = "#75c9f5";
                                e.target.style.transform = "scale(1.1)";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.background = "#75c9f5";
                                e.target.style.color = "#fff";
                                e.target.style.transform = "scale(1)";
                            }}
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>

            {/* Meet the Team Section */}
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    padding: "50px",
                    borderRadius: "10px",
                    width: "80%",
                    position: "relative",
                    zIndex: 2,
                }}
            >
                {/* Right Side: Text Content */}
                <div
                    style={{
                        flex: "0 1 50%",
                        textAlign: "right",
                        padding: "0 40px",
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    <h2
                        style={{
                            fontSize: "3rem",
                            marginBottom: "20px",
                            color: "#40cc8f",
                            textTransform: "uppercase",
                            fontFamily: "'Nunito', sans-serif",
                        }}
                    >
                        Meet the Team
                    </h2>
                    <p
                        style={{
                            fontSize: "1.2rem",
                            lineHeight: "1.5",
                            fontFamily: "'Nunito', sans-serif",
                            color: "#DDD",
                            marginBottom: "30px",
                            textAlign: "right", // Ensures equal-length lines
                        }}
                    >
                        Learn more about the team behind <em style={{ display: "inline-block" }}>Sixth Sense</em> and <em style={{ display: "inline-block" }}>Five Heads</em>,
                        who strive to bring immersive and strategic experiences to life.
                    </p>

                    {/* Button Wrapper - Moves Button to Right */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a
                            href="/about"
                            style={{
                                display: "inline-block",
                                background: "#40cc8f",
                                color: "#fff",
                                padding: "15px 40px",
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                fontFamily: "'Nunito', sans-serif",
                                textDecoration: "none",
                                textTransform: "uppercase",
                                borderRadius: "5px",
                                transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                                clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)",
                            }}
                            onMouseOver={(e) => {
                                e.target.style.background = "#FAFAFA";
                                e.target.style.color = "#40cc8f";
                                e.target.style.transform = "scale(1.1)";
                            }}
                            onMouseOut={(e) => {
                                e.target.style.background = "#40cc8f";
                                e.target.style.color = "#fff";
                                e.target.style.transform = "scale(1)";
                            }}
                        >
                            Learn More
                        </a>
                    </div>
                </div>

                {/* Left Side: Image */}
                <div
                    style={{
                        flex: "0 1 50%",
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    <img
                        src={snakeFeet}
                        alt="Snake Feet Studios"
                        style={{
                            maxWidth: "90%",
                            height: "auto",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 15px rgba(255, 255, 255, 0.2)",
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default FeaturedPanels;
