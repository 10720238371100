import React, { useState } from "react";
import homeImage1 from "../assets/images/home/PromoLogo.png";
import homeImage2 from "../assets/images/home/5_Heads_Promo.png";

const scrollToNextScreen = () => {
    window.scrollBy({
        top: window.innerHeight,
        behavior: "smooth",
    });
};

function OneSlider() {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            image: homeImage1,
            title: "Sixth Sense",
            description: "The adventure begins with Sixth Sense! Explore now!",
            color: "#FF6B35", // Orange for Sixth Sense
            link: "/sixthsense",
        },
        {
            image: homeImage2,
            title: "Five Heads",
            description: "Discover the mysteries of Five Heads!",
            color: "#75C9F5", // Blue for Five Heads
            link: "/fiveheads",
        },
    ];

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    const activeColor = slides[currentSlide].color;

    return (
        <div>
            <section
                style={{
                    position: "relative",
                    width: "100vw",
                    height: "100vh",
                    backgroundImage: `url(${slides[currentSlide].image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingBottom: "60px",
                    fontFamily: "'Nunito', sans-serif",
                }}
            >
                {/* Gradient Overlay */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "40%",
                        background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%)",
                        pointerEvents: "none",
                        zIndex: 1,
                    }}
                ></div>

                {/* Dark Banner */}
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        padding: "35px 0",
                        textAlign: "center",
                        transform: "translateY(125px)",
                        zIndex: 1,
                        clipPath:
                            "polygon(0% 8%, 10% 2%, 20% 6%, 30% 3%, 40% 7%, 50% 4%, 60% 7%, 70% 3%, 80% 6%, 90% 2%, 100% 8%, 100% 92%, 90% 98%, 80% 94%, 70% 97%, 60% 93%, 50% 96%, 40% 92%, 30% 97%, 20% 94%, 10% 98%, 0% 92%)",
                    }}
                >
                    {/* Title */}
                    <h1
                        style={{
                            color: "#fff",
                            fontSize: "3rem",
                            fontWeight: "bold",
                            fontFamily: "'Nunito', sans-serif",
                            textTransform: "uppercase",
                        }}
                    >
                        {slides[currentSlide].title}
                    </h1>

                    {/* Subtitle */}
                    <p
                        style={{
                            color: "#ddd",
                            fontSize: "1.2rem",
                            fontFamily: "'Nunito', sans-serif",
                            maxWidth: "800px",
                            margin: "10px auto",
                        }}
                    >
                        {slides[currentSlide].description}
                    </p>

                    {/* LEARN MORE Button */}
                    <a
                        href={slides[currentSlide].link}
                        style={{
                            display: "inline-block",
                            background: activeColor,
                            color: "#fff",
                            border: "none",
                            padding: "15px 40px",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            cursor: "pointer",
                            transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
                            clipPath:
                                "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)",
                            textTransform: "uppercase",
                            fontFamily: "'Nunito', sans-serif",
                            textDecoration: "none",
                        }}
                        onMouseOver={(e) => {
                            e.target.style.background = "#FAFAFA";
                            e.target.style.color = activeColor;
                            e.target.style.transform = "scale(1.1)";
                        }}
                        onMouseOut={(e) => {
                            e.target.style.background = activeColor;
                            e.target.style.color = "#fff";
                            e.target.style.transform = "scale(1)";
                        }}
                    >
                        LEARN MORE
                    </a>

                    {/* Thumbnail Navigation */}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "15px",
                            margin: "15px 0",
                        }}
                    >
                        {/* Left Arrow */}
                        <button
                            onClick={prevSlide}
                            style={{
                                width: "50px",
                                height: "50px",
                                background: "black",
                                border: `3px solid ${activeColor}`,
                                color: activeColor,
                                borderRadius: "10px",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "0.3s",
                            }}
                        >
                            ◀
                        </button>

                        {/* Thumbnails */}
                        {slides.map((slide, index) => (
                            <img
                                key={index}
                                src={slide.image}
                                alt={slide.title}
                                onClick={() => setCurrentSlide(index)}
                                style={{
                                    width: "130px",
                                    height: "80px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                    border: `4px solid ${index === currentSlide ? activeColor : "black"}`,
                                    transition: "border 0.3s ease-in-out",
                                }}
                            />
                        ))}

                        {/* Right Arrow */}
                        <button
                            onClick={nextSlide}
                            style={{
                                width: "50px",
                                height: "50px",
                                background: "black",
                                border: `3px solid ${activeColor}`,
                                color: activeColor,
                                borderRadius: "10px",
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "0.3s",
                            }}
                        >
                            ▶
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OneSlider;
