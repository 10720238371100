import React from "react";

// Import team icons
import teamIcon1 from "../../assets/images/about/Byron.png";
import teamIcon2 from "../../assets/images/about/Ethan.png";
import teamIcon3 from "../../assets/images/about/3.png";
import teamIcon4 from "../../assets/images/about/4.png";
import teamIcon7 from "../../assets/images/about/7.png";
import teamIcon8 from "../../assets/images/about/8.png";
import teamIcon9 from "../../assets/images/about/9.png";
import teamIcon10 from "../../assets/images/about/10.png";

import rockTexture from "../../assets/images/backgrounds/rock_texture.jpg";

function TeamMem() {
  // Inline style for team members to ensure 5 items per row
  const teamMemberStyle = {
    width: "16%", // Adjusted width to account for margins
    margin: "0 2%", // 2% margin on the left and right
    marginBottom: "50px", // Keep the original marginBottom for consistency
    backgroundColor: "black", // Add black background
    borderRadius: "10px", // Rounded corners
    padding: "20px", // Add some padding
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const sectionStyle = {
    position: "relative",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${rockTexture})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "'Nunito', sans-serif",
    color: "#fff",
  };

  return (
      <section className="team-member-area pt-115 pb-125" style={sectionStyle}>
        <div style={tintOverlayStyle}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three text-center mb-70">
                <h2 style={headerStyle}>Meet the team</h2>
              </div>
            </div>
          </div>
          <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
            {[
              teamIcon1,
              teamIcon2,
              teamIcon3,
              teamIcon4,
              teamIcon7,
              teamIcon8,
              teamIcon9,
              teamIcon10,
            ].map((icon, index) => (
                <div
                    key={index}
                    style={teamMemberStyle}
                    className="team-member-box text-center"
                >
                  <div className="team-member-thumb">
                    <img src={icon} alt="" style={{ width: "100%", borderRadius: "10px" }} />
                  </div>
                </div>
            ))}
          </div>
        </div>
      </section>
  );
}
const tintOverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark tint
  zIndex: 0, // Moves tint **behind** text & buttons
};
const headerStyle = {
  fontSize: "3rem",
  fontWeight: "bold",
  color: "white",
  textTransform: "uppercase",
  fontFamily: "'Nunito', sans-serif",
  zIndex: 1
};

export default TeamMem;
