import React, { useState } from "react";
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg";

const ContactUs = () => {
    // State to manage hover for each button
    const [hoveredButton, setHoveredButton] = useState(null);

    return (
        <div>
            <section
                style={{
                    position: "relative",
                    width: "100vw",
                    height: "100vh",
                    backgroundImage: `url(${rockTexture})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    fontFamily: "'Nunito', sans-serif",
                    color: "#fff",
                    padding: "80px 10%",
                }}
            >
                <div style={tintOverlayStyle}></div>
                <div style={{ paddingTop: "100px" }}></div>

                {/* Title */}
                <h3 style={subHeaderStyle}>NEED TO GET IN TOUCH?</h3>
                <h2 style={headerStyle}>CONTACT US</h2>

                {/* Description */}
                <p style={descriptionStyle}>
                    Wanna get in touch with the team at SnakeFeet Studios? You're in the right spot!
                    If you're looking for game support, visit our support page for an up-to-date
                    knowledge base of the most common questions.
                </p>

                {/* Contact Options */}
                <div style={contactGridStyle}>
                    {contactOptions.map((option, index) => (
                        <div key={index} style={contactCardStyle}>
                            <h3 style={contactTitleStyle}>{option.title}</h3>
                            <p style={contactDescriptionStyle}>{option.description}</p>
                            <button
                                style={{
                                    ...buttonStyle,
                                    background: hoveredButton === index ? "#FAFAFA" : "#40cc8f",
                                    color: hoveredButton === index ? "#40cc8f" : "white",
                                }}
                                onMouseOver={() => setHoveredButton(index)}
                                onMouseOut={() => setHoveredButton(null)}
                            >
                                {hoveredButton === index ? "Coming soon!" : option.buttonText}
                            </button>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

const tintOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark tint
    zIndex: 0, // Moves tint **behind** text & buttons
};

const headerStyle = {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
    fontFamily: "'Nunito', sans-serif",
    zIndex: 1,
};

const subHeaderStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#40cc8f",
    textTransform: "uppercase",
    fontFamily: "'Nunito', sans-serif",
    marginBottom: "10px",
    zIndex: 1,
};

const descriptionStyle = {
    maxWidth: "800px",
    fontSize: "1.2rem",
    position: "relative",
    zIndex: 1,
    margin: "20px 0",
};

const contactGridStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
    marginTop: "40px",
    position: "relative",
    zIndex: 1,
    flexWrap: "wrap", // Ensures responsiveness for smaller screens
};

const contactCardStyle = {
    backgroundColor: "black",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
    width: "250px", // Fixed width to ensure proper alignment
};

const contactTitleStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    fontFamily: "'Nunito', sans-serif",
};

const contactDescriptionStyle = {
    fontSize: "1rem",
    color: "#ddd",
    marginBottom: "15px",
    fontFamily: "'Nunito', sans-serif",
};

const buttonStyle = {
    background: "#40cc8f",
    color: "white",
    border: "none",
    padding: "12px 25px",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "0.2s",
};

const contactOptions = [
    {
        title: "General",
        description: "For general and business inquiries, you're in the right spot! Click below to contact us.",
        buttonText: "GENERAL CONTACT",
    },
    {
        title: "Game Support",
        description: "Need assistance with our games? Visit our support page for FAQs or submit a request.",
        buttonText: "GAME SUPPORT",
    },
    {
        title: "Press & Creators",
        description: "If you're a content creator or media looking for review codes, click below to email us directly.",
        buttonText: "PRESS EMAIL",
    },
];

export default ContactUs;