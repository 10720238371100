import React, { useState } from "react";
import homeImage1 from "../assets/images/home/5_Heads_Promo.png";
import steamIcon from "../assets/images/icons/steam1-removebg-preview.png";
import metaIcon from "../assets/images/icons/meta.png";

function FiveHeadsMain() {
    const [hoveredButton, setHoveredButton] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);

    return (
        <div>
            {/* Hero Section */}
            <section
                style={{
                    position: "relative", // Keeps all child elements properly aligned
                    width: "100vw",
                    height: "100vh", // Full-screen height for consistent layout
                    backgroundImage: `url(${homeImage1})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    textAlign: "center",
                    fontFamily: "'Nunito', sans-serif",
                    color: "#fff",
                }}
            >
                {/* Gradient Overlay for Blending into Black */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        width: "100%",
                        height: "40%",
                        background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%)",
                        pointerEvents: "none",
                        zIndex: 1,
                    }}
                ></div>

                {/* CTA Buttons (Fixed Above the Banner) */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "160px", // Adjusted to keep buttons above the banner
                        left: "50%",
                        transform: "translateX(-50%)", // Ensures buttons stay centered
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "15px",
                        zIndex: 2,
                    }}
                >
                    {["▶ Watch Trailer", "🎮 Play Now", "✉ Newsletter"].map((text, index) => (
                        <div
                            key={index}
                            style={{position: "relative", display: "inline-block"}}
                            onMouseEnter={() => setHoveredButton(index)}
                            onMouseLeave={() => setHoveredButton(null)}
                        >
                            {hoveredButton === index && (
                                <div style={tooltipStyle}>
                                    Coming Soon!
                                </div>
                            )}
                            <button
                                style={buttonStyle}
                                onMouseOver={(e) => handleHover(e, true)}
                                onMouseOut={(e) => handleHover(e, false)}
                            >
                                {text}
                            </button>
                        </div>
                    ))}
                </div>

                {/* "Coming Soon To" Section (Fixed to the Bottom of Background) */}
                <div
                    style={{
                        position: "absolute",
                        bottom: "0", // Keeps it at the bottom of the hero section
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        padding: "15px 0",
                        textAlign: "center",
                        zIndex: 2,
                        clipPath: "polygon(0% 8%, 10% 2%, 20% 6%, 30% 3%, 40% 7%, 50% 4%, 60% 7%, 70% 3%, 80% 6%, 90% 2%, 100% 8%, 100% 92%, 90% 98%, 80% 94%, 70% 97%, 60% 93%, 50% 96%, 40% 92%, 30% 97%, 20% 94%, 10% 98%, 0% 92%)",
                    }}
                >
                    {/* "Coming Soon To" Text */}
                    <div
                        style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#333",
                            fontFamily: "'Nunito', sans-serif",
                            textTransform: "uppercase",
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        Coming Soon To
                    </div>

                    {/* Platform Icons */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "20px",
                            zIndex: 2,
                            marginBottom: "10px",
                        }}
                    >
                        {[steamIcon, metaIcon].map((icon, index) => (
                            <div
                                key={index}
                                style={{position: "relative", display: "inline-block"}}
                                onMouseEnter={() => setHoveredIcon(index)}
                                onMouseLeave={() => setHoveredIcon(null)}
                            >
                                {hoveredIcon === index && (
                                    <div style={tooltipStyle}>
                                        Coming Soon!
                                    </div>
                                )}
                                <img
                                    src={icon}
                                    alt="Platform Icon"
                                    style={{
                                        width: index === 1 ? "47px" : "50px", // Adjusts Meta icon size
                                        height: index === 1 ? "32px" : "50px", // Adjusts height for Meta
                                        cursor: "pointer",
                                        transition: "transform 0.2s ease-in-out",
                                        marginTop: index === 1 ? "8px" : "0px", // Aligns Meta icon properly
                                    }}
                                    onMouseOver={(e) => (e.target.style.transform = "scale(1.2)")}
                                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

// Button Styling
const buttonStyle = {
    background: "#75c9f5",
    color: "#fff",
    border: "none",
    padding: "8px 30px",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out, background 0.2s ease-in-out",
    textTransform: "uppercase",
    borderRadius: "5px",
    fontFamily: "'Nunito', sans-serif",
    clipPath: "polygon(3% 7%, 96% 3%, 100% 20%, 98% 85%, 94% 100%, 5% 95%, 0% 80%, 2% 15%)"
};

// Hover Effect for Buttons
const handleHover = (e, isHovering) => {
    e.target.style.background = isHovering ? "#FAFAFA" : "#75c9f5";
    e.target.style.color = isHovering ? "#75c9f5" : "#fff";
    e.target.style.transform = isHovering ? "scale(1.1)" : "scale(1)";
};

// Tooltip Styling
const tooltipStyle = {
    position: "absolute",
    top: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "black",
    color: "white",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
    transition: "opacity 0.3s ease-in-out",
};

export default FiveHeadsMain;
