import React from "react";
import HeaderOne from "../../components/MainHeader";
import Footer from "../../components/MainFooter";
import MainHero from "../../components/MainHero";
import GameScreenshots from "../../components/GameScreenshots";
import FeaturedPanels from "../../components/FeaturedPanels";
import CommunitySection from "../../components/CommunitySection";

function IndexOne() {
  return (
    <>
      <HeaderOne />
      <main>
        {/* main photo */}
        <MainHero />

        {/* in game screenshots and descriptions */}
        <GameScreenshots />
        <FeaturedPanels />
        <CommunitySection/>

      </main>
      <Footer />
    </>
  );
}

export default IndexOne;
