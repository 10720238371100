import React from "react";
import HeaderOne from "../../components/MainHeader.jsx";
import Footer from "../../components/MainFooter.jsx";


// Import game images
import SixthSenseMain from "../../components/SixthSenseMain";
import CommunitySection from "../../components/CommunitySection";
import SixthSenseFeatures from "../../components/SixthSenseFeatures";
function Overview() {
  return (
    <>
      <HeaderOne />
      <main>
        <SixthSenseMain/>
        <SixthSenseFeatures/>
        <CommunitySection/>
      </main>
      <Footer />
    </>
  );
}

export default Overview;
