import React, { useState } from "react";
import Slider from "react-slick";
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg"; // Rock texture background

// Import screenshots
import inGameWheel from "../assets/images/game/sixthSenseWheel.png";
import inGame1 from "../assets/images/game/sixthSenseInGame5.png";
import inGame2 from "../assets/images/game/sixthSenseInGame1.png";
import inGame4 from "../assets/images/game/sixthSenseInGame2.png";
import inGame3 from "../assets/images/game/sixthSenseInGame3.png";
import inGame5 from "../assets/images/game/sixthSenseInGame4.png";

function GameScreenshots() {
    const [selectedScreenshot, setSelectedScreenshot] = useState(null);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 2000,
        autoplay: true,
        arrows: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        center: false,
        swipe: false,
        draggable: false,
        pauseOnHover: false,
    };

    const screenshots = [
        { title: "Invisibility", image: inGame3, description: "Evade your Assassin or stalk your Target with stealth." },
        { title: "Sixth Sense", image: inGame4, description: "If Target is invisible, use your Sixth Sense to find them." },
        { title: "Eliminate Targets", image: inGame5, description: "Find and eliminate your targets, one by one." },
        { title: "Investigate Bodies", image: inGame1, description: "Investigate dead bodies to learn who killed them." },
        { title: "Wheel", image: inGameWheel, description: "Solve the targeting order and identify potential Assassins." },
        { title: "Spy Network", image: inGame2, description: "Hire Spies to look out for your Target and Assassin." }
    ];

    return (
        <div
            style={{
                position: "relative",
                backgroundImage: `url(${rockTexture})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                padding: "100px 0",
                overflow: "hidden",
            }}
        >

            {/* Dark Tint Effect */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    padding: "200px 0"
                }}
            ></div>
            {/* Featured Screenshots */}
            <section
                style={{
                    position: "relative",
                    zIndex: 2,
                    padding: "80px 10%",
                    textAlign: "center",
                    color: "white",
                }}
            >
                <h2
                    style={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        fontFamily: "'Nunito', sans-serif",
                        marginBottom: "40px",
                        textAlign: "left",
                        textTransform: "uppercase",
                    }}
                >
                    Featured Screenshots
                </h2>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)", // Always 3 columns
                        gap: "30px",
                        justifyContent: "center",
                        maxWidth: "2500px", // Limits width so it never collapses into a single row
                        margin: "0 auto", // Centers the grid
                    }}
                >
                    {screenshots.map((screenshot, index) => (
                        <div
                            key={index}
                            style={{
                                position: "relative",
                                overflow: "hidden",
                                borderRadius: "10px",
                                transition: "transform 0.3s ease-in-out",
                                cursor: "pointer",
                                backgroundColor: "rgba(255, 255, 255, 0.1)",
                                padding: "15px",
                                border: "5px solid transparent",
                                width: "100%", // Ensures equal width distribution
                            }}
                            onClick={() => setSelectedScreenshot(screenshot)}
                            onMouseOver={(e) => {
                                e.currentTarget.style.border = "5px solid white";
                                e.currentTarget.style.boxShadow = "0px 0px 20px rgba(255, 255, 255, 0.8)";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.border = "5px solid transparent";
                                e.currentTarget.style.boxShadow = "none";
                            }}
                        >
                            <img
                                src={screenshot.image}
                                alt={screenshot.title}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    maxWidth: "100%",
                                    display: "block",
                                    borderRadius: "10px",
                                }}
                            />
                        </div>
                    ))}
                </div>
            </section>

            {/* Popup Modal for Enlarged Screenshot */}
            {selectedScreenshot && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.85)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000,
                    }}
                    onClick={() => setSelectedScreenshot(null)}
                >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: "#222",
                            padding: "20px",
                            borderRadius: "10px",
                            textAlign: "center",
                            maxWidth: "80%",
                        }}
                    >
                        <img
                            src={selectedScreenshot.image}
                            alt={selectedScreenshot.title}
                            style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "10px",
                            }}
                        />
                        <h3 style={{
                            color: "white",
                            fontSize: "2rem",
                            fontWeight: "bold",
                            marginTop: "15px",
                            fontFamily: "'Nunito', sans-serif"
                        }}>
                        {selectedScreenshot.title}
                        </h3>
                        <p style={{ color: "#ddd", fontSize: "1.2rem" , fontFamily: "'Nunito', sans-serif"}}>{selectedScreenshot.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GameScreenshots;
