import React from "react";
import HeaderOne from "../../components/MainHeader";
import Footer from "../../components/MainFooter";
import ContactUs from "../../components/ContactUs";
import CommunitySection from "../../components/CommunitySection";


function Contact() {
  return (
    <>
      <HeaderOne />
      <main>
          <ContactUs/>
        <CommunitySection />
      </main>
      <Footer />
    </>
  );
}

export default Contact;
