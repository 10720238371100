import React, { useState } from "react";
import steamIcon from "../assets/images/icons/steam1.png";
import discordIcon from "../assets/images/icons/discord.png";
import youtubeIcon from "../assets/images/icons/youtube1.png";
import instagramIcon from "../assets/images/icons/instagram.png";
import twitchIcon from "../assets/images/icons/twitch2-removebg.png";
import tiktokIcon from "../assets/images/icons/tiktok-removebg.png";
import butterflyIcon from "../assets/images/icons/bluesky.png";
import snakeFeet from "../assets/images/icons/-Logo_Circle_blkBG_Flat_Cropped.PNG"; // SnakeFeet logo

function Footer() {
    const [hoveredItem, setHoveredItem] = useState(null);

    return (
        <div style={{ backgroundColor: "black" }}>
            <footer
                style={{
                    position: "relative",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    padding: "40px 0",
                    textAlign: "center",
                    color: "#000",
                    fontFamily: "'Nunito', sans-serif",
                    backgroundColor: "white",
                    clipPath: "polygon(0% 8%, 10% 2%, 20% 6%, 30% 3%, 40% 7%, 50% 4%,60% 7%, 70% 3%, 80% 6%, 90% 2%, 100% 8%,100% 100%, 0% 100%)"
                }}
            >
                {/* Logo */}
                <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                    <img src={snakeFeet} alt="SnakeFeet Logo" style={{ width: "100px", height: "auto" }} />
                </div>

                {/* Social Media Icons */}
                <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "15px", position: "relative" }}>
                    {[
                        steamIcon,
                        discordIcon,
                        youtubeIcon,
                        instagramIcon,
                        twitchIcon,
                        tiktokIcon,
                        butterflyIcon
                    ].map((icon, index) => {
                        let iconSize = "30px";

                        if (index === 4) iconSize = "37px"; // Twitch slightly bigger
                        if (index === 5 || index === 6) iconSize = "27px"; // TikTok & Bluesky smaller

                        return (
                            <div
                                key={index}
                                style={{ position: "relative", display: "inline-block" }}
                                onMouseEnter={() => setHoveredItem(`icon-${index}`)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                {/* Tooltip */}
                                {hoveredItem === `icon-${index}` && (
                                    <div style={tooltipStyle}>
                                        Coming Soon!
                                    </div>
                                )}

                                {/* Icon */}
                                <img
                                    src={icon}
                                    alt="Social Icon"
                                    style={{
                                        width: iconSize,
                                        height: iconSize,
                                        cursor: "pointer",
                                        transition: "transform 0.2s",
                                        marginTop: index === 4 ? "-2px" : "0px"
                                    }}
                                    onMouseOver={(e) => (e.target.style.transform = "scale(1.2)")}
                                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* Navigation Links with Tooltip */}
                <nav
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        color: "#000",
                        marginBottom: "10px",
                    }}
                >
                    {["Home", "Games", "Publishing", "About", "Careers", "Contact", "Account"].map((item, index) => (
                        <div
                            key={index}
                            style={{ position: "relative", display: "inline-block" }}
                            onMouseEnter={() => index !== 0 && setHoveredItem(`nav-${index}`)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            {/* Tooltip */}
                            {hoveredItem === `nav-${index}` && (
                                <div style={tooltipStyle}>
                                    Coming Soon!
                                </div>
                            )}

                            {/* Link */}
                            <a
                                style={{
                                    color: index === 0 ? "#40cc8f" : "#000",
                                    textDecoration: "none",
                                    transition: "color 0.2s",
                                }}
                                onMouseOver={(e) => (e.target.style.color = "#40cc8f")}
                                onMouseOut={(e) => (e.target.style.color = index === 0 ? "#40cc8f" : "#000")}
                            >
                                {item}
                            </a>
                        </div>
                    ))}
                </nav>

                {/* Copyright & Attribution */}
                <p
                    style={{
                        fontSize: "0.8rem",
                        color: "#444",
                        paddingBottom: "10px",
                    }}
                >
                    © All Rights Reserved SnakeFeet Entertainment 2025 |{" "}
                    <a style={{ color: "#000", textDecoration: "underline" }}>
                        Privacy
                    </a>
                </p>
            </footer>
        </div>
    );
}

// Tooltip Style
const tooltipStyle = {
    position: "absolute",
    top: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "black",
    color: "white",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
    transition: "opacity 0.3s ease-in-out",
    fontFamily: "'Nunito', sans-serif",
};

export default Footer;
