import React from "react";
import HeaderOne from "../../components/MainHeader";
import Footer from "../../components/MainFooter";
import AboutSnakeFeet from "../../components/AboutSnakeFeet";
import TeamGallery from "./TeamGallery";
import CommunitySection from "../../components/CommunitySection";

function About() {
  return (
    <>
      <HeaderOne />
      <main>
        <AboutSnakeFeet />
        <TeamGallery />
        <CommunitySection/>
      </main>
      <Footer />
    </>
  );
}

export default About;
