import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import logoImage from "../assets/images/logos/Logo_blkBG_Flat_Cropped.PNG";

function HeaderOne() {
    const [isGameDropdownOpen, setGameDropdownOpen] = useState(false);

    useEffect(() => {
        if ($(".menu-area li.menu-item-has-children ul").length) {
            $(".menu-area .navigation li.menu-item-has-children").append(
                '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
            );
        }
    }, []);

    return (
        <header>
            <ScrollToTop smooth top="500" color="#000" />
            <div
                id="header"
                className="main-header menu-area menu"
                style={headerStyle}
            >
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="main-menu">
                                <nav>
                                    <div className="navbar-wrap push-menu d-none d-lg-flex">
                                        <div className="logo">
                                            <Link to="/">
                                                <img
                                                    src={logoImage}
                                                    alt="Logo"
                                                    style={{ height: "100px" }}
                                                />
                                            </Link>
                                        </div>

                                        <ul className="navigation" style={navContainerStyle}>
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    style={navLinkStyle}
                                                    onMouseOver={(e) => (e.target.style.color = "#000")}
                                                    onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                >
                                                    Home
                                                </NavLink>
                                            </li>

                                            {/* Game Menu with Dropdown */}
                                            <li
                                                style={{ position: "relative" }}
                                                onMouseEnter={() => setGameDropdownOpen(true)}
                                                onMouseLeave={() => setGameDropdownOpen(false)}
                                            >
                                                <NavLink
                                                    to="#"
                                                    style={gameLinkStyle}
                                                    onMouseOver={(e) => (e.target.style.color = "#000")}
                                                    onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                >
                                                    Games
                                                </NavLink>
                                                {isGameDropdownOpen && (
                                                    <ul style={dropdownStyle}>
                                                        <li>
                                                            <NavLink
                                                                to="/sixthsense"
                                                                style={dropdownItemStyle}
                                                                onMouseOver={(e) => (e.target.style.color = "#000")}
                                                                onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                            >
                                                                Sixth Sense
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                to="/fiveheads"
                                                                style={dropdownItemStyle}
                                                                onMouseOver={(e) => (e.target.style.color = "#000")}
                                                                onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                            >
                                                                Five Heads
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                )}
                                            </li>

                                            <li>
                                                <NavLink
                                                    to="/about"
                                                    style={navLinkStyle}
                                                    onMouseOver={(e) => (e.target.style.color = "#000")}
                                                    onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                >
                                                    About
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/contact"
                                                    style={navLinkStyle}
                                                    onMouseOver={(e) => (e.target.style.color = "#000")}
                                                    onMouseOut={(e) => (e.target.style.color = "#fff")}
                                                >
                                                    Contact
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            <div className="menu-backdrop" />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

// Styles
const headerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const navContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "40px",
    listStyle: "none",
    padding: 0,
};

const navLinkStyle = {
    fontSize: "18px",
    fontFamily: "Arial, sans-serif",
    textDecoration: "none",
    color: "#fff",
    padding: "10px 20px",
    display: "block",
    position: "relative",
    transition: "color 0.3s ease-in-out",
};

const gameLinkStyle = {
    ...navLinkStyle,
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    fontWeight: "bold",
    position: "relative",
    transition: "background 0.3s ease-in-out, color 0.3s ease-in-out",
};

const dropdownStyle = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    listStyle: "none",
    padding: "10px 0",
    margin: "0",
    width: "100%", // Makes the dropdown align with the "Game" button width
    borderRadius: "5px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
};

const dropdownItemStyle = {
    display: "block",
    padding: "10px",
    color: "#fff",
    textDecoration: "none",
    fontFamily: "'Nunito', sans-serif",
    transition: "color 0.2s ease-in-out",
};

export default HeaderOne;
