import React from "react";
import HeaderOne from "../../components/MainHeader.jsx";
import Footer from "../../components/MainFooter.jsx";


// Import game images
import FiveHeadsMain from "../../components/FiveHeadsMain";
import CommunitySection from "../../components/CommunitySection";
import FiveHeadsAbout from "../../components/FiveHeadsAbout";
function Overview() {
  return (
      <>
        <HeaderOne />
        <main>
          <FiveHeadsMain/>
          <FiveHeadsAbout/>
          <CommunitySection/>
        </main>
        <Footer />
      </>
  );
}

export default Overview;
