import React, { useState } from "react";
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg";
import Ace from "../assets/images/game/ace.png";
import Two from "../assets/images/game/two.png";
import Three from "../assets/images/game/three.png";
import Lighting from "../assets/images/game/lighting.png";
import King from "../assets/images/game/king.png";
import Face from "../assets/images/game/face.png";

const aboutExpansions = [
    {
        title: "THE GAME",
        description: (
            <>
                Five prisoners sit around a table, each with a card on their forehead—visible to everyone but themselves.
                In the first two rounds, they must figure out where they rank from lowest to highest, using logic,
                deduction, and psychology. In the final round, each player takes a leap of faith, guessing their exact
                card. Every guess is a clue, every mistake a setback—can you crack the code?
            </>
        ),
        images: [Ace, Two, Three], // Images for "The Game"
    },
    {
        title: "THE RUN",
        description: (
            <>
                The Run is a high-stakes gauntlet of logic and teamwork. You and your team of five must survive a series
                of escalating challenges, ending each level in the correct order to advance. Before moving forward,
                you’ll vote on a power to aid your deduction and a poison that makes the next level even tougher.
                With each round growing more intense, will your team adapt, or will the increasing chaos break your streak?
            </>
        ),
        images: [Lighting, King, Face], // Images for "The Run"
    },
];

function FiveHeadsAbout() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % aboutExpansions.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + aboutExpansions.length) % aboutExpansions.length);
    };

    return (
        <div style={containerStyle}>
            {/* Dark Tint Overlay (Now Behind Everything) */}
            <div style={tintOverlayStyle}></div>

            {/* Main Content */}
            <div style={{ position: "relative", zIndex: 1 }}>
                {/* ABOUT Section with Arrows */}
                <div style={aboutTitleContainer}>
                    <button onClick={prevSlide} style={navButtonStyle}>◀</button>
                    <h1 style={headerStyle}>ABOUT</h1>
                    <button onClick={nextSlide} style={navButtonStyle}>▶</button>
                </div>

                {/* Navigation Menu */}
                <div style={navMenuStyle}>
                    {aboutExpansions.map((expansion, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            style={{
                                ...navButtonTextStyle,
                                backgroundColor: index === currentIndex ? "#75c9f5" : "transparent",
                                color: index === currentIndex ? "white" : "#75c9f5",
                            }}
                        >
                            {expansion.title}
                        </button>
                    ))}
                </div>

                {/* Description */}
                <p style={descriptionStyle}>{aboutExpansions[currentIndex].description}</p>

                {/* Image Gallery */}
                <div style={imageGalleryStyle}>
                    {aboutExpansions[currentIndex].images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={aboutExpansions[currentIndex].title}
                            style={{
                                ...imageStyle,
                                transform: currentIndex === 0
                                    ? `rotate(${index % 2 === 0 ? "-5deg" : "5deg"})` // Slight tilt for The Game images
                                    : `rotate(${index % 2 === 0 ? "10deg" : "-10deg"})`, // More slanted for The Run images
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

// Styles
const containerStyle = {
    position: "relative",
    backgroundImage: `url(${rockTexture})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    padding: "80px 10%",
    fontFamily: "'Nunito', sans-serif",
    textAlign: "center",
    color: "white",
    overflow: "hidden",
};

const tintOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark tint
    zIndex: 0, // Moves tint **behind** text & buttons
};

const aboutTitleContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginBottom: "30px",
    position: "relative",
    zIndex: 1, // Ensures it's **above** the tint
};

const headerStyle = {
    fontSize: "3rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "'Nunito', sans-serif"
};

const navMenuStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginBottom: "20px",
    position: "relative",
    zIndex: 1, // Ensures it's visible above the tint
};

const navButtonTextStyle = {
    border: "none",
    padding: "10px 20px",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "0.3s",
    borderRadius: "5px",
    textTransform: "uppercase",
};

const navButtonStyle = {
    background: "#75c9f5",
    border: "none",
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bold",
    cursor: "pointer",
    padding: "15px",
    borderRadius: "5px",
    transition: "0.3s",
    position: "relative",
    zIndex: 1, // Ensures it's **above** the tint
};

const descriptionStyle = {
    fontSize: "1.2rem",
    maxWidth: "900px",
    margin: "0 auto",
    lineHeight: "1.6",
    position: "relative",
    zIndex: 1, // Ensures it's **above** the tint
};

const imageGalleryStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "20px",
    position: "relative",
    zIndex: 1, // Ensures images are visible above the tint
};

const imageStyle = {
    width: "180px",
    height: "auto",
    borderRadius: "5px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
    transition: "transform 0.3s ease-in-out",
};

export default FiveHeadsAbout;
