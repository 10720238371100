import React from "react";
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg";
import Logo from "../assets/images/logos/Logo_blkBG_Flat_Cropped.PNG";

const AboutSnakeFeet = () => {
    return (
        <div>
            <section
                style={{
                    position: "relative",
                    width: "100vw",
                    height: "100vh",
                    backgroundImage: `url(${rockTexture})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    fontFamily: "'Nunito', sans-serif",
                    color: "#fff",
                }}
            >
                <div style={tintOverlayStyle}></div>

                {/* Logo */}
                <img
                    src={Logo}
                    alt="SnakeFeet Studios Logo"
                    style={{ width: "500px", marginTop: "50px", marginBottom: "20px", position: "relative", zIndex: 1 }}
                />
                <h2 style={headerStyle}>ABOUT US</h2>
                {/* About Us Text */}
                <p style={{ maxWidth: "800px", fontSize: "1.2rem", position: "relative", zIndex: 1, margin: "20px 0" }}>
                    SnakeFeet Studios is an indie game studio founded by Byron and Ethan Ma in 2023, dedicated to crafting
                    innovative multiplayer and social deduction experiences. We’re currently developing <strong><em>Sixth Sense</em></strong>,
                    a 50-player stealth-based battle royale, and <strong><em>Five Heads</em></strong>, a team-based social deduction card game.
                    We’re excited to share our journey and welcome you to join us across all platforms—stay tuned for updates and surprises.
                    As we gear up for release, meet the faces behind SnakeFeet Studios!
                </p>
            </section>
        </div>
    );
};

const tintOverlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Dark tint
    zIndex: 0, // Moves tint **behind** text & buttons
};
const headerStyle = {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
    fontFamily: "'Nunito', sans-serif",
    zIndex: 1
};

export default AboutSnakeFeet;
