import React, { useState } from "react";
import sixthSenseAboutImage from "../assets/images/about/aboutSixSense.png";
import rockTexture from "../assets/images/backgrounds/rock_texture.jpg";
import Invisibility from "../assets/images/game/sixthSenseInGame3.png";
import SixSense from "../assets/images/game/sixthSenseInGame2.png";
import ElimTarget from "../assets/images/game/sixthSenseInGame4.png";
import InvestigateBodies from "../assets/images/game/sixthSenseInGame5.png";
import TheWheel from "../assets/images/game/sixthSenseWheel.png";
import SpyNetwork from "../assets/images/game/sixthSenseInGame1.png";

const expansions = [
    {
        title: "Invisibility",
        image: Invisibility,
        description: (
            <>
                Vanish into thin air with Invisibility, the perfect tool for escaping danger or
                setting up the perfect ambush. While hidden from all eyes—including spies and
                bystanders—your Assassin gains a heightened Sixth Sense, making every disappearance
                a risky gamble. Use it wisely—because the unseen can still be hunted.
            </>
        ),
    },
    {
        title: "Six Sense",
        image: SixSense,
        description: (
            <>
                Sixth Sense transforms disappearance into a trail to be followed. When your target
                vanishes, you lose sight of them—but gain an uncanny awareness of their presence.
                A mysterious force pulls you closer, narrowing the hunt without ever revealing
                their exact location. Trust your instincts, close the distance, and strike before
                they slip away again.
            </>
        ),
    },
    {
        title: "Eliminate Targets",
        image: ElimTarget,
        description: (
            <>
                Eliminate Targets and climb the chain of survival. You’re assigned one target at
                a time—hunt them down, take them out, and inherit their target. The cycle continues
                until only one player remains. Stay sharp, because while you’re hunting, someone
                else is hunting you.
            </>
        ),
    },
    {
        title: "Investigate Bodies",
        image: InvestigateBodies,
        description: (
            <>
                Investigate Dead Bodies to unravel the web of assassins and victims. Every fallen
                player holds a clue—who they were, who took them out, and what it means for your survival.
                Gather intel, track patterns, and use special tools to turn knowledge into power.
                The more you know, the deadlier you become.
            </>
        ),
    },
    {
        title: "The Wheel",
        image: TheWheel,
        description: (
            <>
                The Wheel puts the power of deduction in your hands. Using intel gathered from fallen players,
                you can uncover the hidden chain of assassins and targets—gaining crucial hints about who
                might be hunting you. Spin the wheel, mark a suspect, and stay one step ahead—because knowing
                your Assassin is the first step to surviving them.
            </>
        ),
    },
    {
        title: "Spy Network",
        image: SpyNetwork,
        description: (
            <>
                Hidden across the map, spies can keep watch from trees, rocks, and shadows, feeding you intel
                on your Target’s movements or your suspected Assassin. But trust is an illusion—spies work
                for the highest bidder, and your enemies can turn your own eyes against you.
            </>
        ),
    },
];

function SixthSensePage() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % expansions.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + expansions.length) % expansions.length);
    };

    return (
        <div
            style={{
                position: "relative",
                backgroundImage: `url(${rockTexture})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                paddingTop: "80px",
                fontFamily: "'Nunito', sans-serif",
                textAlign: "center",
            }}
        >
            {/* Dark Tint Overlay */}
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                    zIndex: 0,
                }}
            ></div>

            {/* About Section */}
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "1100px",
                    margin: "0 auto",
                    padding: "40px 40px",
                    gap: "50px",
                    zIndex: 2,
                }}
            >
                <div style={{ flex: "1", textAlign: "center" }}>
                    <h1 style={headerStyle}>ABOUT</h1>
                    <h2 style={subHeaderStyle}>Sixth Sense</h2>
                    <p style={textStyle}>
                        <strong><em>Sixth Sense</em></strong> is a stealth-driven, deduction-based battle royale where
                        knowledge is your greatest weapon. Set in a pre-historic settlement, players take on the roles
                        of cunning thieves, enigmatic rangers, and arcane tricksters, hunting their target while evading
                        their own assassin. Find dead bodies, hire spies, and use your Sixth Sense—because in this game,
                        the last one standing is the one who saw it coming.
                    </p>
                </div>

                <div style={{ flex: "1", display: "flex", justifyContent: "flex-end" }}>
                    <img
                        src={sixthSenseAboutImage}
                        alt="Sixth Sense Artwork"
                        style={imageStyle}
                    />
                </div>
            </div>

            {/* Features Section */}
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    color: "#fff",
                    padding: "60px",
                    marginTop: "50px",
                    borderRadius: "10px",
                }}
            >
                {/* Left Image */}
                <div style={{ flex: "1", position: "relative", zIndex: 1, width:"1000px" }}>
                    <img
                        src={expansions[currentIndex].image}
                        alt={expansions[currentIndex].title}
                        style={imageStyle}
                    />
                </div>

                {/* Right Content */}
                <div style={{
                    flex: "1",
                    paddingLeft: "40px",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center horizontally
                    textAlign: "center" // Center text content
                }}>
                    {/* Title with Fixed Arrow Position */}
                    <div style={titleContainerStyle}>
                        <button onClick={prevSlide} style={navButtonStyle}>◀</button>
                        <h1 style={titleStyle}>{expansions[currentIndex].title.toUpperCase()}</h1>
                        <button onClick={nextSlide} style={navButtonStyle}>▶</button>
                    </div>

                    {/* Tab Navigation */}
                    <div style={{display: "flex", gap: "15px", marginBottom: "15px"}}>
                        {expansions.map((expansion, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                style={{
                                    background: index === currentIndex ? "#FF6B35" : "transparent",
                                    border: "none",
                                    padding: "10px 20px",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    color: index === currentIndex ? "#fff" : "#FF6B35",
                                    cursor: "pointer",
                                    transition: "0.3s",
                                }}
                            >
                                {expansion.title.toUpperCase()}
                            </button>
                        ))}
                    </div>

                    {/* Expansion Description */}
                    <p style={descriptionTextStyle}>{expansions[currentIndex].description}</p>
                </div>
            </div>
        </div>
    );
}

// Fixed Position Styling
const titleContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "500px", // Fixed width ensures arrows stay in place
    margin: "0 auto",
    position: "relative",
};

const titleStyle = {
    fontSize: "3rem",
    fontWeight: "bold",
    textAlign: "center",
    flex: "1", // Ensures title stays centered
    fontFamily: "'Nunito', sans-serif",
};

const navButtonStyle = {
    background: "#FF6B35",
    border: "none",
    color: "#fff",
    fontSize: "1.5rem",
    padding: "10px 15px",
    cursor: "pointer",
    borderRadius: "5px",
};

const headerStyle = { fontSize: "3rem", fontWeight: "bold", textTransform: "uppercase", fontFamily: "'Nunito', sans-serif"  };
const subHeaderStyle = { fontSize: "2rem", fontWeight: "bold", color: "#FF6B35", textTransform: "uppercase", fontFamily: "'Nunito', sans-serif" };
const textStyle = { fontSize: "1.2rem", maxWidth: "600px", color: "#ddd", textAlign: "center" };
const descriptionTextStyle = { fontSize: "1.2rem", maxWidth: "600px", color: "#ddd", textAlign: "center" };
const imageStyle = { width: "500px", height: "auto", borderRadius: "5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)" };

export default SixthSensePage;
